<template>
  <div class="container-xs" style="display: grid; gap: 2rem">
    <AppBackHeader @back="$refs.exitPassenger.show()"
      >TRAVEL COMPANION</AppBackHeader
    >
    <AppDataExclaimer />
    <TravelerForm
      ref="form"
      :traveler="traveler"
      @valid="value => (isValid = value)"
      @update-start="onUpdateStart"
      @update-success="onUpdateSuccess"
      @update-error="onUpdateError"
      @update-end="onUpdateEnd"
      @delete-start="onDeleteStart"
      @delete-success="onDeleteSuccess"
      @delete-error="onDeleteError"
      @delete-end="onDeleteEnd"
    />
    <AppTextButton
      style="justify-self: flex-end"
      @click="$refs.removePassenger.show()"
      >REMOVE</AppTextButton
    >
    <!-- <AppTextButton
      style="justify-self: flex-end"
      @click="$refs.form.deleteTraveler()"
      >REMOVE</AppTextButton
    > -->
    <XButton
      id="submit-button"
      type="large"
      style="width: 100%"
      :disabled="!isValid"
      @click="$refs.form.updateTraveler()"
    >
      {{ this.$t('form.save') }}
    </XButton>
    <LoadingModalFullScreen
      ref="loadingModal"
      text="please wait"
      non-closable
    />
    <InfoModal
      ref="infoModal"
      title="Error"
      text="Something went wrong"
      cta="try again"
      @cta="$refs.infoModal.hide()"
    />
    <InfoModal
      ref="exitPassenger"
      title="ARE YOU SURE YOU WANT TO EXIT?"
      text="Your changes to travel companion will not be saved."
      hide-close-button
      cta="EXIT"
      alt-cta="CANCEL"
      @cta="$router.push('/profile/companions')"
      @alt-cta="$refs.exitPassenger.hide()"
    />
    <InfoModal
      ref="removePassenger"
      title="ARE YOU SURE YOU WANT TO REMOVE?"
      text="You're about to remove a travel companion along with all their data."
      hide-close-button
      cta="REMOVE"
      alt-cta="CANCEL"
      @cta="$refs.form.deleteTraveler()"
      @alt-cta="$refs.removePassenger.hide()"
    />
  </div>
</template>

<script>
import AppBackHeader from '@/components-rf/AppBackHeader.vue'
import AppTextButton from '@/components-rf/AppTextButton.vue'
import TravelerForm from '@/components-rf/forms/TravelerForm.vue'
import InfoModal from '@/components-rf/modals/InfoModal.vue'
import LoadingModalFullScreen from '@/components-rf/modals/LoadingModalFullScreen.vue'
import { mapState } from 'vuex'
import XButton from '../../components/common/global/XButton.vue'
import AppDataExclaimer from '../../components-rf/AppDataExclaimer.vue'

export default {
  name: 'TravelerEdit',
  components: {
    AppBackHeader,
    AppTextButton,
    TravelerForm,
    LoadingModalFullScreen,
    InfoModal,
    XButton,
    AppDataExclaimer,
  },
  data() {
    return {
      isValid: false,
    }
  },
  computed: {
    ...mapState('TravelerModule', ['travelers']),
    traveler() {
      return this.travelers.find(
        traveler => traveler.id === +this.$route.params.id,
      )
    },
  },
  methods: {
    onUpdateStart() {
      this.$refs.loadingModal.show()
    },
    onUpdateSuccess() {
      this.$router.push('/profile/companions')
    },
    onUpdateError() {
      this.$refs.infoModal.show()
    },
    onUpdateEnd() {
      this.$refs.loadingModal.hide()
    },
    onDeleteStart() {
      this.$refs.removePassenger.hide()
      this.$refs.loadingModal.show()
    },
    onDeleteSuccess() {
      this.$router.push('/profile/companions')
    },
    onDeleteError() {
      this.$refs.infoModal.show()
    },
    onDeleteEnd() {
      this.$refs.loadingModal.hide()
    },
  },
}
</script>

<style scoped lang="scss"></style>
